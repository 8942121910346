import React from 'react';
import { Box, Text } from 'rebass';
import { useTranslation } from 'react-i18next';

import { VOZZiWebsitePage } from '../../types/VozziWebsitePage.type';
import { FAQType } from '../../types/types';
import SectionHeader from '../../components/layout/SectionHeader/SectionHeader.component';
import { NewStandardPageLayout } from '../../layouts/newStadardPage.layout';
import { Container } from '../../layouts/main.layout';

const FaqPage: VOZZiWebsitePage = (props) => {
  const { t } = useTranslation();

  const faqs: FAQType[] = props.pageContext.faqs || [];
  const meta = {
    title: t('meta_FaqTitle'),
    description: t('meta_FaqDescription'),
  };

  const og = {
    image: 'https://vozzi.app/img/meta-img/packages-webshop-meta-og-2-0.jpg',
  };

  return (
    <NewStandardPageLayout
      mainHeading={t('faq_Heading')}
      backgroundImage="bg/packages.jpg"
      backgroundImageMobile="bg/packages-mobile@3x.jpg"
      meta={meta}
      og={og}
    >
      <Container style={{ paddingTop: 60 }}>
        <SectionHeader title={t('faq_Heading')} underline mt={{ _: 4, sm: 6 }} mb={{ _: 4, sm: 5 }} />

        {faqs &&
          faqs.map((faq: FAQType, i: number) => {
            return (
              <Box key={i} mb={{ _: 5 }}>
                <Text fontSize={{ _: 3, sm: 4 }} mb={{ _: 3 }} lineHeight={{ _: 1.3 }}>
                  {faq.attributes.question}
                </Text>
                <div dangerouslySetInnerHTML={{ __html: faq.attributes.answer }} />
              </Box>
            );
          })}
      </Container>
    </NewStandardPageLayout>
  );
};

export default FaqPage;
